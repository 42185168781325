import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getArtistUrl, join as joinURL } from 'utils/url';
import { Helmet } from 'react-helmet';
import {
  metaResolveUrl as resolveUrl,
  translateKeywords,
} from 'utils/metaUtils';

type Props = {
  artistId: number;
  artistName: string;
  imageUrl?: string;
  name?: string;
  pagePath: string;
  siteUrl: string;
};

export default function Head({
  siteUrl,
  pagePath,
  imageUrl,
  artistId,
  artistName,
  name,
}: Props) {
  const translate = useTranslate();

  const description = translate(
    "Listen to music you'll love! Create a custom radio station from your favorite songs by {artistName} on iHeart.",
    { artistName },
  );
  const keywords = `${name}, ${artistName}, ${translateKeywords(
    translate,
    'Music, Songs, Top, Best, Popular, Lyrics, Download, iHeartRadio, iHeart, Radio',
  )}`;
  const artistUrl = getArtistUrl(artistId, artistName);
  const title = translate(
    'Stream Free Songs by {artistName} & Similar Artists',
    { artistName },
  );

  const url = resolveUrl(siteUrl, pagePath);

  return (
    <>
      <GenericHead
        deeplink={`play/custom/artist/${artistId}`}
        description={description}
        image={imageUrl}
        legacyDeeplink={`play/custom/artist/${artistId}`}
        metaDescription={description}
        ogType="profile"
        title={title}
        twitterCard="summary_large_image"
      />
      <Helmet
        meta={[
          { content: keywords, name: 'keywords' },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: url, property: 'og:audio' },
          { content: joinURL(siteUrl, artistUrl!), property: 'music:musician' },
        ]}
      />
    </>
  );
}
